import { Wrapper } from "../../components"
import faqData from "../../utils/sss.json"
import React, { useState, useMemo } from 'react'
import { ChevronDown, ChevronUp } from "lucide-react"

const FaqItem = ({ question, answer, isOpen, toggleOpen }) => {
    return (
        <div className="border-b border-gray-200 py-4">
            <button
                className="flex w-full items-center justify-between text-left"
                onClick={toggleOpen}
            >
                <span className="text-lg font-medium text-white">{question}</span>
                <span className="text-2xl text-white">{isOpen ? <ChevronUp/> : <ChevronDown/>}</span>
            </button>
            {isOpen && (
                <div className="mt-2 text-white">
                    <p>{answer}</p>
                </div>
            )}
        </div>
    );
}

const Faq = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [openItems, setOpenItems] = useState({});

    const filteredFAQs = useMemo(() => {
        return faqData.filter(
            item =>
                item.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.answer.toLowerCase().includes(searchTerm.toLowerCase())
        )
    }, [searchTerm])

    const toggleItem = (question) => {
        setOpenItems(prev => ({ ...prev, [question]: !prev[question] }))
    }

    return (
        <Wrapper >
            <h3 className="text-white text-5xl text-center font-semibold my-4">Sıkça Sorulan Sorular</h3>
            <div className="mb-6">
                <input
                    type="text"
                    placeholder="Soru ara..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full rounded-md border border-gray-300 p-2"
                />
            </div>

            <div className="mt-16">
                {filteredFAQs.map(item => (
                    <FaqItem
                        key={item.question}
                        {...item}
                        isOpen={openItems[item.question] || false}
                        toggleOpen={() => toggleItem(item.question)}
                    />
                ))}

            </div>

        </Wrapper>
    );
}

export { Faq }