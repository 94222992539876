import {createBrowserRouter} from "react-router-dom";

import MainLayout from "../layout/MainLayout";
import {MainScreen,PrivacyPolicy,TradingView,RedirectMobileApp,Faq} from "../screens";


const routes = createBrowserRouter([
    {
        element:<MainLayout/>,
        children:[
            {
                path:"/",
                element:<MainScreen/>
            },
            {
                path:"privacy-policy",
                element:<PrivacyPolicy/>
            },
            {
                path:"tradingview",
                element:<TradingView/>
            },
            {
                path:"mobileApp",
                element:<RedirectMobileApp/>
            },
            {
                path:"sss",
                element:<Faq/>
            }
        ]
    }
]);

export default routes;
