import GmsLogo from '../../assets/logo.svg';

function Logo () {
    return (
        <div className="px-10">
            <img width="150" src={GmsLogo} alt="Logo"/>
        </div>
    );
}

export {Logo}
